define('m14a/controllers/schraubenausrichtung', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

        displayErrors: true,
        einschraubwinkel: "45.0",
        winkelscheibe: false,

        einschraubwinkelDisabled: true,
        winkelscheibeDisabled: true,

        ttEinschraubwinkel: Ember['default'].computed('i18n.locale', function () {

            return this.get('i18n').t('wertebereich') + "0.0 - 5.0 [cm]";
        }),

        i18n: Ember['default'].inject.service(),

        validations: {
            einschraubwinkel: {
                numericality: {
                    greaterThanOrEqualTo: 0.0,
                    lessThanOrEqualTo: 90.0
                }
            }
        },

        schraubenausrichtungen: [{
            name: "Schrauben parallel",
            value: false
        }, {
            name: "Schrauben kreuzweise",
            value: true
        }]

    });

});