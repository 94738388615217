define('m14a/models/verbindungsmittel', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var Verbindungsmittel = DS['default'].Model.extend({

		title: DS['default'].attr("String"),
		children: DS['default'].hasMany('tag', { async: true }),
		parent: DS['default'].belongsTo('tag', { async: true })

	});

	Verbindungsmittel.reopenClass({
		FIXTURES: [{ id: 1, title: 'Family', parent: 0, children: [10, 11] }, { id: 10, title: 'Susan', parent: [1], children: [100, 101] }, { id: 11, title: 'Luda', parent: [1], children: [102, 103] }, { id: 100, title: 'Josh', parent: [10], children: [] }, { id: 101, title: 'Moses', parent: [10], children: [] }, { id: 102, title: 'Verdi', parent: [11], children: [] }, { id: 103, title: 'Gaya', parent: [11], children: [] }]
	});

	exports['default'] = Verbindungsmittel;

});