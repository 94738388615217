define('m14a/components/custom-input', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, TooltipsterComponent) {

    'use strict';

    exports['default'] = TooltipsterComponent['default'].extend({
        tagName: 'input',

        classNames: ['custom-input-class'],

        // define properties
        title: 'My tooltip',

        position: 'left',

        functionInit: function functionInit(origin, content) {
            return "this value will become the content of the tooltip";
        }
    });

});