define('m14a/controllers/geometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,
    c26: false,
    displayErrors: true,
    material: {},
    honk: null,
    ttHoeheHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
    }),
    ttBreiteHT: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 2.0 - 9999.9 [cm]";
    }),
    ttSystemlaenge: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.5 - 999.9 [m]";
    }),
    ttLaengeFehlstelle: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]";
    }),
    ttUeberkopplungslaenge: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]";
    }),
    ttLageSanierung: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]";
    }),
    ttAuflagerlaenge: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]";
    }),
    i18n: Ember['default'].inject.service(),
    self: undefined,
    hauptnebentraeger: '',
    systemlaenge: "",
    htbreite: "",
    hthoehe: "",
    laengeFehlstelle: "",
    ueberkopplungslaenge: "",
    lageSanierung: "0.0",
    auflagerlaenge: "",
    toolTip: "test tooltip",
    htfkl: 5,
    ntfkl: 5,
    htholz: 0,
    ntholz: 0,
    httraeger: true,
    winkel: '',
    stahl: false,
    lageSanierungDisabled: true,

    validations: {
      htbreite: {
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      hthoehe: {
        numericality: {
          greaterThanOrEqualTo: 2.0,
          lessThanOrEqualTo: 9999.9
        }
      },
      systemlaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.5,
          lessThanOrEqualTo: 999.9
        }
      },
      laengeFehlstelle: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.9
        }
      },
      ueberkopplungslaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.9
        }
      },
      lageSanierung: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.9
        }
      },
      auflagerlaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    htfklarray: [],
    ntfklarray: [],
    sanierungsvarianten: [],
    sanierungsvariante: 0,

    vgebohrt: [],
    teOldVorgebohrt: false,
    teOldLaubholz: false,

    te1vorgebohrtHilfetext: "",
    te1vorgebohrtHilfetextFocused: false,

    initTrigger: false,

    init: function init() {
      this._super();
      var self = this;

      this.setSelectFieldsContent();

      this.set('htfkl', 5);
      this.set('htholz', 0);

      // this.set('sanierungsvarianten', sanierungsvariantenDB.sanierungsvarianten_de);
      // this.set('sanierungsvariante', 0);

      var geometriedata = this.controllerFor('application').get('model').geometrie.objectAt(0);

      geometriedata.set('MatKeyTimberElementOld', "0");
      geometriedata.set('FKLKeyTimberElementOld', "5");

      this.set('hauptnebentraeger', this.get('i18n').t('bemessungslast').toString());

      var supercontroller = self.controllerFor('supercontroller');
      supercontroller.testAusgabe("hallo");
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in geometrie: ");
        console.log(values);
      }

      var htbreiteEvent = {
        target: {
          name: "htbreite"
        }
      };
      var hthoeheEvent = {
        target: {
          name: "hthoehe"
        }
      };
      var laengeFehlstelleEvent = {
        target: {
          name: "laengeFehlstelle"
        }
      };
      var ueberkopplungslaengeEvent = {
        target: {
          name: "ueberkopplungslaenge"
        }
      };
      var lageSanierungEvent = {
        target: {
          name: "lageSanierung"
        }
      };
      var auflagerlaengeEvent = {
        target: {
          name: "auflagerlaenge"
        }
      };
      var systemlaengeEvent = {
        target: {
          name: "systemlaenge"
        }
      };

      this.set('openFromFile', true);

      this.set('systemlaenge', (Number(values.LSys) / 100).toFixed(2));
      this.setX3D(Number(values.LSys) / 100, systemlaengeEvent);

      this.set('htholz', parseInt(values.MatKeyTimberElementOld));
      this.set('htfkl', parseInt(values.FKLKeyTimberElementOld));
      this.set('teOldVorgebohrt', values.TimberElementOld_vorgebohrt);

      this.set('htbreite', values.TimberElement1_b);
      this.setX3D(parseInt(values.TimberElement1_b), htbreiteEvent);

      this.set('hthoehe', values.TimberElement1_h);
      this.setX3D(parseInt(values.TimberElement1_h), hthoeheEvent);

      this.set('laengeFehlstelle', values.dL);
      this.setX3D(parseInt(values.dL), laengeFehlstelleEvent);

      this.set('ueberkopplungslaenge', values.L_Kopplung);
      this.setX3D(parseInt(values.L_Kopplung), ueberkopplungslaengeEvent);

      this.set('lageSanierung', values.dL_Lager);
      this.setX3D(parseInt(values.dL_Lager), lageSanierungEvent);

      this.set('auflagerlaenge', values.L_Lager);
      this.setX3D(parseInt(values.L_Lager), auflagerlaengeEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setHtMaterialien();
      self.setHtFKL();
      self.setSanierungsvarianten();
      self.setVgebohrt();
    },

    setHtMaterialien: function setHtMaterialien() {
      var self = this;
      var indices = [0, 1, 13, 30, 12];
      self.set('htmaterial', self.getSelectFieldContent('materialienDB', indices));
    },

    setHtFKL: function setHtFKL(indices) {
      var self = this;
      var indices = [];

      // this.set('htfklarray', self.getFklassen(self.get('htholz')));

      if (this.get('htholz') === 1) {
        indices = [20, 21, 24, 22];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh', indices));
        self.set('htfkl', 20);
      } else if (self.get('htholz') === 13) {
        indices = [30, 31, 34, 32];
        self.set('htfklarray', self.getSelectFieldContent('fkl_bsh_kombi', indices));
        self.set('htfkl', 30);
      } else if (self.get('htholz') === 0) {
        indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        self.set('htfklarray', self.getSelectFieldContent('fkl_vh', indices));
        self.set('htfkl', 5);
      } else if (self.get('htholz') === 30) {
        indices = [301];
        self.set('htfklarray', self.getSelectFieldContent('fkl_baubuche', indices));
        self.set('htfkl', 301);
      } else if (self.get('htholz') === 12) {
        indices = [50, 51, 52];
        self.set('htfklarray', self.getSelectFieldContent('fkl_vollholz_laubholz', indices));
        self.set('htfkl', 50);
      }
    },

    setVgebohrt: function setVgebohrt() {
      var self = this;
      var indices = [false, true];
      self.set('vgebohrt', self.getSelectFieldContent('vgebohrt', indices));
    },

    setSanierungsvarianten: function setSanierungsvarianten() {
      var self = this;
      var indices = [0, 1];
      self.set('sanierungsvarianten', self.getSelectFieldContent('sanierungsvarianten', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {
        case 'materialienDB':
          dbContent = materialienDB.materialien_de_m03;
          break;
        case 'fkl_vh':
          dbContent = fklDB_new.fkl_vh.fklassen;
          break;
        case 'fkl_bsh':
          dbContent = fklDB_new.fkl_bsh.fklassen;
          break;
        case 'fkl_bsh_kombi':
          dbContent = fklDB_new.fkl_bsh_kombi.fklassen;
          break;
        case 'fkl_baubuche':
          dbContent = fklDB_new.fkl_baubuche.fklassen;
          break;
        case 'fkl_vollholz_laubholz':
          dbContent = fklDB_new.fkl_vollholz_laubholz.fklassen;
          break;
        case 'sanierungsvarianten':
          dbContent = sanierungsvariantenDB.sanierungsvarianten_de;
          break;
        case 'vgebohrt':
          dbContent = vorgebohrt.auswahlfeld;
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('systemlaenge').indexOf(",") !== -1) {
          this.set('systemlaenge', this.get('systemlaenge').replace(",", "."));
        }

        if (this.get('htbreite').indexOf(",") !== -1) {
          this.set('htbreite', this.get('htbreite').replace(",", "."));
        }

        if (this.get('hthoehe').indexOf(",") !== -1) {
          this.set('hthoehe', this.get('hthoehe').replace(",", "."));
        }

        if (this.get('laengeFehlstelle').indexOf(",") !== -1) {
          this.set('laengeFehlstelle', this.get('laengeFehlstelle').replace(",", "."));
        }

        if (this.get('ueberkopplungslaenge').indexOf(",") !== -1) {
          this.set('ueberkopplungslaenge', this.get('ueberkopplungslaenge').replace(",", "."));
        }

        if (this.get('lageSanierung').indexOf(",") !== -1) {
          this.set('lageSanierung', this.get('lageSanierung').replace(",", "."));
        }

        if (this.get('auflagerlaenge').indexOf(",") !== -1) {
          this.set('auflagerlaenge', this.get('auflagerlaenge').replace(",", "."));
        }
      }
    }).observes('htbreite', 'hthoehe', 'systemlaenge', 'laengeFehlstelle', 'ueberkopplungslaenge', 'lageSanierung', 'auflagerlaenge'),

    changeHtSt: (function () {
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('istHt', this.get('httraeger'));
    }).observes('httraeger'),

    istHt: (function () {
      return this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('istHt');
    }).property('httraeger'),

    watchHtMaterial: (function () {
      var self = this;

      self.set('teOldLaubholz', vorgebohrt.materialien.findBy('id', self.get('htholz')).laubholz);
      self.set('teOldVorgebohrt', vorgebohrt.materialien.findBy('id', self.get('htholz')).value);

      self.setHtFKL();
    }).observes('htholz'),

    watchComboboxes: (function () {

      var self = this;

      var application = self.controllerFor('application');
      // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      // applicationdata.set('treeLoaded', false);
      // applicationdata.set('initialized', false);
      // applicationdata.set('pdfErstellt', false);
      //
      // application.set('verbindungsmittelInvalid', true);
      //
      // var downloadcenter = self.controllerFor('downloadcenter');
      // downloadcenter.set('pdfErstellt', false);

      self.resetVerbindungsmittel();

      var geometriedata = this.controllerFor('application').get('model').geometrie.objectAt(0);

      geometriedata.set('MatKeyTimberElementOld', this.get('htholz'));
      geometriedata.set('FKLKeyTimberElementOld', this.get('htfkl'));

      self.controllerFor('lasteinwirkung').setNklassen();
      self.controllerFor('lasteinwirkung').send('validation', self.controllerFor('lasteinwirkung').get('winkelScherkraft'), { target: { name: "winkelScherkraft" } });
    }).observes('htholz', 'htfkl', 'httraeger'),

    watchTeOldVorgebohrt: (function () {
      var self = this;

      self.resetVerbindungsmittel();

      var geometrie = self.controllerFor('application').get('model').geometrie.objectAt(0);
      geometrie.set('TimberElementOld_vorgebohrt', self.get('teOldVorgebohrt'));
    }).observes('teOldVorgebohrt'),

    resetVerbindungsmittel: function resetVerbindungsmittel() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      // applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      // downloadcenter.set('dxfErstellt', false);
    },

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var self = this;

      if (x3d.get('ergebnisGeladen') === true) {
        self.resetGrafikToDefault();
      }

      value = parseFloat(value).toFixed(1);

      // console.log(event.target);

      switch (event.target.name) {
        case 'htbreite':
          if (this.errors.htbreite.length === 0) {
            bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('z', value);
            x3d.set('htBreiteEingetragen', true);

            self.setLagerBreite();
            self.setSchraubenlaenge();
            self.setSchraubenPosition();
          } else {
            x3d.set('htBreiteEingetragen', false);
          }
          break;
        case 'hthoehe':
          if (this.errors.hthoehe.length === 0) {
            bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('y', value);
            x3d.set('htHoeheEingetragen', true);
            self.setSchraubenlaenge();
            self.setSchraubenPosition();
          } else {
            x3d.set('htHoeheEingetragen', false);
          }
          break;
        case 'auflagerlaenge':
          if (this.errors.auflagerlaenge.length === 0) {
            bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).set('x', value);
            x3d.set('auflagerlaengeEingetragen', true);
            self.setBautteillaenge();
          } else {
            x3d.set('auflagerlaengeEingetragen', false);
          }
          break;
        case 'systemlaenge':
          if (this.errors.systemlaenge.length === 0) {
            x3d.set('systemlaengeEingetragen', true);
            x3d.set('systemlaenge', value * 100);
          } else {
            x3d.set('systemlaengeEingetragen', false);
          }
          break;
        case 'ueberkopplungslaenge':
          if (this.errors.ueberkopplungslaenge.length === 0) {
            x3d.set('ueberkopplungslaengeEingetragen', true);
            x3d.set('ueberkopplungslaenge', value);
            // bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('x', (Number(value)+3));
            // bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).set('x', (Number(value)+3));
            self.setBautteillaenge();
          } else {
            x3d.set('ueberkopplungslaengeEingetragen', false);
          }
          break;
        case 'laengeFehlstelle':
          if (this.errors.laengeFehlstelle.length === 0) {
            x3d.set('laengeFehlstelleEingetragen', true);
            x3d.set('laengeFehlstelle', value);
            self.setBautteillaenge();
          } else {
            x3d.set('laengeFehlstelleEingetragen', false);
          }
          break;
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    resetGrafikToDefault: function resetGrafikToDefault() {
      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      // bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('x', 33)

      // x3d.set('abstaendeAnzeigen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      x3d.set('transformHelper', !x3d.get('transformHelper'));
    },

    setSchraubenlaenge: function setSchraubenlaenge(zMaterial1, zMaterial2, winkel) {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var nameM1 = 'material1';
      var nameM2 = 'material2';

      if (x3d.get('vortriebsrichtungVT') === false) {
        var nameM1 = 'material2';
        var nameM2 = 'material1';
      }

      var y1 = Number(bauteile.findBy('id', nameM1).get('boxsizes').objectAt(0).get('y'));
      var z1 = Number(bauteile.findBy('id', nameM1).get('boxsizes').objectAt(0).get('z'));

      var y2 = Number(bauteile.findBy('id', nameM2).get('boxsizes').objectAt(0).get('y'));
      var z2 = Number(bauteile.findBy('id', nameM2).get('boxsizes').objectAt(0).get('z'));

      var winkel = Number(bauteile.findBy('id', 'schraubeStandard1').get('rotations').objectAt(0).get('z'));

      var a = 0,
          b = 0;

      if (Math.abs(winkel) === 1.5708 || y1 / 2 > z1 / 2) {
        a = z1 / 2;
      } else {
        a = y1 / 2;
      }

      if (Math.abs(winkel) === 1.5708 || y2 / 2 > z2) {
        b = z2;
      } else {
        b = y2 / 2;
      }

      if (x3d.get('stahlVerstaerkung') && x3d.get('istProfilstahl')) {
        b = Number(bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('materialdicke'));
      }

      var tiefe = a + b;

      var schraubenLaenge = tiefe / Math.cos(1.5708 - winkel);

      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {
          item.get('screwProperties').objectAt(0).set('length', Math.abs(schraubenLaenge));
          item.get('screwProperties').objectAt(0).set('threadlengthtip', Math.abs(schraubenLaenge));
        }
      });
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },

    setSchraubenPosition: function setSchraubenPosition() {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var hLager = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y'));

      var yMaterial1 = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y'));
      var zMaterial1 = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z'));

      var yMaterial2 = 0;

      if (x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
        yMaterial2 = Number(bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('y'));
      } else {
        yMaterial2 = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'));
      }

      var zMaterial2 = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z'));

      var a = 0;

      var yPosTemp = hLager;

      if (yMaterial1 / 2 > yMaterial2 / 2) {
        yPosTemp = yPosTemp + yMaterial2 / 2;
      } else {
        yPosTemp = yPosTemp + yMaterial1 / 2;
      }

      var zPos = 0;

      var winkelRichtung = 1;

      if (yMaterial1 / 2 > zMaterial1 / 2) {
        a = zMaterial1 / 2;
      } else {
        a = yMaterial1 / 2;
      }

      var count = 0;
      bauteile.forEach(function (item) {
        if (item.get('typ') === 'standardSchraube') {

          var winkel = Number(item.get('rotations').objectAt(0).get('z'));

          var yPos = yPosTemp - winkelRichtung * Math.tan(1.5708 - Math.abs(winkel)) * a;
          item.get('translations').objectAt(0).set('y', yPos);

          if (Math.abs(winkel) !== 1.5708) {
            zPos = winkel / Math.abs(winkel) * zMaterial1 / 2 - a * winkel / Math.abs(winkel) * winkelRichtung;
          }

          item.get('translations').objectAt(0).set('z', zPos);
        }
      });

      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },

    setLagerBreite: function setLagerBreite() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var breiteBalken = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z'));
      var breiteVerstaerkung = 0;

      if (x3d.get('istProfilstahl') === true && x3d.get('stahlVerstaerkung') === true) {
        breiteVerstaerkung = Number(bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z'));
      } else {
        breiteVerstaerkung = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z'));
      }

      var neueLagerbreite = Number(breiteBalken) + 2 * Number(breiteVerstaerkung);

      bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).set('z', neueLagerbreite);
      bauteile.findBy('id', 'lager12').get('boxsizes').objectAt(0).set('z', breiteBalken);
      bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).set('z', breiteBalken);
    },

    setBautteillaenge: function setBautteillaenge() {
      var self = this;
      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = application.get('model').x3ddefault.objectAt(0).get('bauteile');

      var laenge = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x')) + Number(x3d.get('laengeFehlstelle')) + Number(x3d.get('ueberkopplungslaenge'));

      bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).set('x', Number(x3d.get('ueberkopplungslaenge')) + 3);
      bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).set('x', laenge);
      bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).set('x', laenge);

      self.setSystemlaengeMinimum(Number(self.get('laengeFehlstelle')), Number(self.get('ueberkopplungslaenge')), Number(self.get('auflagerlaenge')));
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('geometrieInvalid', false);

            // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            // applicationdata.set('treeLoaded', false);
            // applicationdata.set('initialized', false);
            // applicationdata.set('pdfErstellt', false);
            //
            // application.set('verbindungsmittelInvalid', true);
            //
            // var downloadcenter = self.controllerFor('downloadcenter');
            // downloadcenter.set('pdfErstellt', false);

            self.resetVerbindungsmittel();

            var geometriedata = self.controllerFor('application').get('model').geometrie.objectAt(0);

            geometriedata.set('TimberElement1_b', parseFloat(self.get('htbreite')).toFixed(1));
            geometriedata.set('TimberElement1_h', parseFloat(self.get('hthoehe')).toFixed(1));

            geometriedata.set('LSys', parseFloat(self.get('systemlaenge')).toFixed(2) * 100);

            geometriedata.set('dL', parseFloat(self.get('laengeFehlstelle')).toFixed(1));
            geometriedata.set('L_Kopplung', parseFloat(self.get('ueberkopplungslaenge')).toFixed(1));
            geometriedata.set('dL_Lager', parseFloat(self.get('lageSanierung')).toFixed(1));
            geometriedata.set('L_Lager', parseFloat(self.get('auflagerlaenge')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('geometrieInvalid', true);
          }
        });
      },

      htbreiteIsSelected: function htbreiteIsSelected() {
        var htbreiteaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('htbreiteAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htbreiteAktiv', !htbreiteaktiv);

        if (this.get('htbreite') !== "") {
          var htbreitework = parseFloat(this.get('htbreite').replace(",", "."));
          var htbreiterounded = htbreitework.toFixed(1);
          this.set('htbreite', htbreiterounded);
          this.send('validation', htbreiterounded, { target: { name: "htbreite" } });
        }

        if (htbreiteaktiv === false) {
          document.getElementsByName('htbreite')[0].setSelectionRange(0, this.get('htbreite').length);
        }
      },

      hthoeheIsSelected: function hthoeheIsSelected() {
        var hthoeheaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hthoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hthoeheAktiv', !hthoeheaktiv);

        if (this.get('hthoehe') !== "") {
          var hthoehework = parseFloat(this.get('hthoehe').replace(",", "."));
          var hthoeherounded = hthoehework.toFixed(1);
          this.set('hthoehe', hthoeherounded);
          this.send('validation', hthoeherounded, { target: { name: "hthoehe" } });
        }

        if (hthoeheaktiv === false) {
          document.getElementsByName('hthoehe')[0].setSelectionRange(0, this.get('hthoehe').length);
        }
      },

      systemlaengeIsSelected: function systemlaengeIsSelected() {
        var systemlaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('systemlaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('systemlaengeAktiv', !systemlaengeAktiv);

        if (this.get('systemlaenge') !== "") {
          var systemlaengework = parseFloat(this.get('systemlaenge').replace(",", "."));
          var systemlaengerounded = systemlaengework.toFixed(2);
          this.set('systemlaenge', systemlaengerounded);
          this.send('validation', systemlaengerounded, { target: { name: "systemlaenge" } });
        }

        if (systemlaengeAktiv === false) {
          document.getElementsByName('systemlaenge')[0].setSelectionRange(0, this.get('systemlaenge').length);
        }
      },

      laengeFehlstelleIsSelected: function laengeFehlstelleIsSelected() {
        var laengeFehlstelleAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('laengeFehlstelleAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('laengeFehlstelleAktiv', !laengeFehlstelleAktiv);

        if (this.get('laengeFehlstelle') !== "") {
          var laengeFehlstellework = parseFloat(this.get('laengeFehlstelle').replace(",", "."));
          var laengeFehlstellerounded = laengeFehlstellework.toFixed(1);
          this.set('laengeFehlstelle', laengeFehlstellerounded);
          this.send('validation', laengeFehlstellerounded, { target: { name: "laengeFehlstelle" } });
        }

        if (laengeFehlstelleAktiv === false) {
          document.getElementsByName('laengeFehlstelle')[0].setSelectionRange(0, this.get('laengeFehlstelle').length);
        }
      },

      ueberkopplungslaengeIsSelected: function ueberkopplungslaengeIsSelected() {
        var ueberkopplungslaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ueberkopplungslaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ueberkopplungslaengeAktiv', !ueberkopplungslaengeAktiv);

        if (this.get('ueberkopplungslaenge') !== "") {
          var ueberkopplungslaengework = parseFloat(this.get('ueberkopplungslaenge').replace(",", "."));
          var ueberkopplungslaengerounded = ueberkopplungslaengework.toFixed(1);
          this.set('ueberkopplungslaenge', ueberkopplungslaengerounded);
          this.send('validation', ueberkopplungslaengework, { target: { name: "ueberkopplungslaenge" } });
        }

        if (ueberkopplungslaengeAktiv === false) {
          document.getElementsByName('ueberkopplungslaenge')[0].setSelectionRange(0, this.get('ueberkopplungslaenge').length);
        }
      },

      lageSanierungIsSelected: function lageSanierungIsSelected() {
        var lageSanierungAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('lageSanierungAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('lageSanierungAktiv', !lageSanierungAktiv);

        if (this.get('lageSanierung') !== "") {
          var lageSanierungwork = parseFloat(this.get('lageSanierung').replace(",", "."));
          var lageSanierungrounded = lageSanierungwork.toFixed(1);
          this.set('lageSanierung', lageSanierungrounded);
          this.send('validation', lageSanierungrounded, { target: { name: "lageSanierungrounded" } });
        }

        if (lageSanierungAktiv === false) {
          document.getElementsByName('lageSanierung')[0].setSelectionRange(0, this.get('lageSanierung').length);
        }
      },

      auflagerlaengeIsSelected: function auflagerlaengeIsSelected() {
        var auflagerlaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('auflagerlaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('auflagerlaengeAktiv', !auflagerlaengeAktiv);

        if (this.get('auflagerlaenge') !== "") {
          var auflagerlaengework = parseFloat(this.get('auflagerlaenge').replace(",", "."));
          var auflagerlaengerounded = auflagerlaengework.toFixed(1);
          this.set('auflagerlaenge', auflagerlaengerounded);
          this.send('validation', auflagerlaengerounded, { target: { name: "auflagerlaenge" } });
        }

        if (auflagerlaengeAktiv === false) {
          document.getElementsByName('auflagerlaenge')[0].setSelectionRange(0, this.get('auflagerlaenge').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // Ende applicationdataJSON

    // hilfeTexte obeserver

    observes_htholzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htholz", self.get('htholz').toString());
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('htholz', 'initTrigger'),

    observes_htfklHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "htfkl", "0");
      self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
    }).observes('htfkl', 'initTrigger'),

    observes_te1vorgebohrtHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Bauteile", "te1vorgebohrt", "0");
      if (Number(self.get("htholz")) !== 30) {
        self.controllerFor("supercontroller").setHilfetexte("geometrie", hilfetextArray);
      }
    }).observes('teOldVorgebohrt', 'initTrigger'),

    // hilfeTexte display properties

    display_htholzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htholzHilfetext'));
    }).property('htholzHilfetext'),

    display_htfklHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('htfklHilfetext'));
    }).property('htfklHilfetext'),

    display_te1vorgebohrtHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('te1vorgebohrtHilfetext'));
    }).property('te1vorgebohrtHilfetext'),

    setSystemlaengeMinimum: function setSystemlaengeMinimum(laengeFehlstelle, ueberkopplungslaenge, auflagerlaenge) {
      var self = this;
      var value = (laengeFehlstelle + ueberkopplungslaenge + auflagerlaenge + 50) / 100;
      self.set('validations.systemlaenge.numericality.greaterThanOrEqualTo', value);
      self.set('ttSystemlaenge', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.systemlaenge.numericality.greaterThanOrEqualTo).toFixed(1) + " -  999.9 [m]");

      this.send('validation', -1, { target: { name: "foobar" } });
    }

  });

});