define('m14a/models/lasteinwirkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var lasteinwirkung = DS['default'].Model.extend({

		Var_Bem: DS['default'].attr('string'),
		NKL_ID: DS['default'].attr('string'),
		NKL_Text: DS['default'].attr('string'),
		staendigeEinwirkungen: DS['default'].attr('string'),
		verEinw1: DS['default'].attr('string'),
		verEinw2: DS['default'].attr('string'),
		vorbelastung: DS['default'].attr('string'),
		kled1: DS['default'].attr('string'),
		kled2: DS['default'].attr('string'),
		eigengewicht: DS['default'].attr('string')

	});

	lasteinwirkung.reopenClass({
		FIXTURES: [{
			id: 1,

			Var_Bem: "0",
			NKL_ID: 1,
			NKL_Text: "Nutzungsklasse 1",
			staendigeEinwirkungen: "0",
			verEinw1: "0",
			verEinw2: "0",
			vorbelastung: "0",
			eigengewicht: "201",
			kled1: "201",
			kled2: "201"

		}]
	});

	exports['default'] = lasteinwirkung;

});