define('m14a/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr(''),
    viewpointHelper: DS['default'].attr(''),
    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),

    systemlaenge: DS['default'].attr('number', { defaultValue: 0 }),
    laengeFehlstelle: DS['default'].attr('number', { defaultValue: 20 }),
    ueberkopplungslaenge: DS['default'].attr('number', { defaultValue: 30 }),
    hWinkel: DS['default'].attr('number', { defaultValue: 90 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 0 }),
    bemessungslast: DS['default'].attr('number', { defaultValue: 0 }),

    zweiseitigeVerstaerkung: DS['default'].attr('boolean', { defaultValue: true }),
    stahlVerstaerkung: DS['default'].attr('boolean', { defaultValue: false }),
    istProfilstahl: DS['default'].attr('boolean', { defaultValue: false }),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    htbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    hthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    breiteMaterial2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    hoeheMaterial2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    bemessungslastAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    laengeFehlstelleAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ueberkopplungslaengeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    lageSanierungAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    auflagerlaengeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    hWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    htBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    htHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    breiteMaterial2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    hoeheMaterial2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    bemessungslastEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    laengeFehlstelleEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    ueberkopplungslaengeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    lageSanierungEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    auflagerlaengeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    hWinkelEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    istHt: DS['default'].attr('boolean', { defaultValue: true }),
    schraubenGekreuzt: DS['default'].attr('boolean', { defaultValue: false }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 1.47;
      } }),

    startDistance: DS['default'].attr('number', { defaultValue: 14.7 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 14.7 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    AbstandLager: DS['default'].attr('number', { defaultValue: 10 }),
    AbstandVM: DS['default'].attr('number', { defaultValue: 15 }),

    randAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    randAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand2: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),

    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});