define('m14a/models/verstaerkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var verstaerkung = DS['default'].Model.extend({
		nBauteile: DS['default'].attr('string'),
		VerstaerkungHolz: DS['default'].attr('boolean'),
		MatKeyTimberElementReI: DS['default'].attr('string'),
		FKLKeyTimberElementReI: DS['default'].attr('string'),
		breite_ReI: DS['default'].attr('string'),
		hoehe_ReI: DS['default'].attr('string'),
		Flachstahl: DS['default'].attr('boolean'),
		f_yk: DS['default'].attr('string'),
		Bezeichnung: DS['default'].attr('string'),
		ProfilName: DS['default'].attr('string'),
		profilHoehe: DS['default'].attr('string'),
		profilBreite: DS['default'].attr('string'),
		TimberElementReI_vorgebohrt: DS['default'].attr('boolean')

	});

	verstaerkung.reopenClass({
		FIXTURES: [{
			id: 1,
			nBauteile: "2",
			VerstaerkungHolz: true,
			MatKeyTimberElementReI: "0",
			FKLKeyTimberElementReI: "5",
			breite_ReI: "0.0",
			hoehe_ReI: "0.0",
			Flachstahl: false,
			f_yk: "23.5",
			Bezeichnung: "23.5",
			ProfilName: "U 120",
			profilHoehe: "12.0",
			profilBreite: "5.5",
			TimberElementReI_vorgebohrt: false
		}]
	});

	exports['default'] = verstaerkung;

});