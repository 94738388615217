define('m14a/models/geometrie', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var geometrie = DS['default'].Model.extend({

		MatKeyTimberElementOld: DS['default'].attr('string'),
		FKLKeyTimberElementOld: DS['default'].attr('string'),
		TimberElement1_b: DS['default'].attr('string'),
		TimberElement1_h: DS['default'].attr('string'),

		LSys: DS['default'].attr('string'),

		VarSanierung: DS['default'].attr('string'),
		dL: DS['default'].attr('string'),
		L_Kopplung: DS['default'].attr('string'),
		dL_Lager: DS['default'].attr('string'),
		dy_UK: DS['default'].attr('string'),
		L_Lager: DS['default'].attr('string'),
		TimberElementOld_vorgebohrt: DS['default'].attr('boolean')

	});

	geometrie.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyTimberElementOld: "0",
			FKLKeyTimberElementOld: "5",
			TimberElement1_b: "20",
			TimberElement1_h: "20",

			LSys: "0.5",

			VarSanierung: "0",
			dL: "0.0",
			L_Kopplung: "0.0",
			dL_Lager: "0.0",
			dy_UK: "0.0",
			L_Lager: "0.0",
			TimberElementOld_vorgebohrt: false
		}]
	});

	exports['default'] = geometrie;

});