define('m14a/components/arctwo-d', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'arctwo-d',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var cords = '0 0 0';

      var yVerschiebung = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 20;
      var zVerschiebung = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 20 + Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 20;

      cords = '0 ' + yVerschiebung + ' ' + zVerschiebung;

      // if (name === 'vWinkelScherkraft'){
      //   cords = '0 0 0';
      // }else if (name === 'vWinkel'){
      //
      //   var ursprungsradius = 3;
      //   var winkel = (Math.PI/2)-(Number(x3d.get('vWinkel'))*Math.PI)/180;
      //
      //   // var hypolaenge = ursprungsradius/(Math.cos(winkel));
      //   // var hypodelta = ursprungsradius - hypolaenge;
      //
      //   var xVerschiebung = (Math.cos(winkel)*ursprungsradius) - (ursprungsradius);
      //   var zVerschiebung1 = -xVerschiebung*(Math.tan(winkel));
      //
      //   cords = xVerschiebung+' 0 '+zVerschiebung1;
      //
      //   //console.log(x3d.objectAt(0).get('vWinkelScherkraft') +' '+ winkel +' '+ xVerschiebung +' '+ zVerschiebung1);
      // }
      return cords;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var values = '0 0 0 0';
      // if (name === 'vWinkelScherkraft'){
      //   values = '1 0 0 -1.5707';
      // }else if (name === 'vWinkel'){
      //   var winkel = 1.5707-(Number(x3d.get('vWinkelScherkraft'))*Math.PI)/180;
      //   values = '0 1 0 '+winkel;
      // }
      return values;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    anfangsWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 0;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
      var vwinkelScherkraft = Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;

      if (name === 'vWinkelScherkraft' && vwinkelScherkraft > 0) {
        value = vwinkel + 0.00001;
      } else if (name === 'vWinkelScherkraft' && vwinkelScherkraft < 0) {
        value = vwinkel + vwinkelScherkraft;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    endWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 0;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
      var vwinkelScherkraft = Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;

      if (name === 'vWinkelScherkraft' && vwinkelScherkraft > 0) {
        value = vwinkel + vwinkelScherkraft + 0.0001;
      }if (name === 'vWinkelScherkraft' && vwinkelScherkraft < 0) {
        value = vwinkel;
      } else if (name === 'vWinkel') {
        value = vwinkel + 0.0001;
      }
      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    radius: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = 2;
      var value = ueberstand + 4;

      // if (name === 'vWinkelScherkraft'){
      //   value = 1.5;
      // }else if (name === 'vWinkel'){
      //   value = 3;
      // }
      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkelScherkraftAktiv'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('vWinkelScherkraftAktiv') === true && name === 'vWinkelScherkraft' && typ === 'vWinkelScherkraft') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && name === 'vWinkel' && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.vWinkelScherkraftAktiv', 'model.firstObject.vWinkelAktiv')

  });

  exports['default'] = Transform;

});