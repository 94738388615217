define('m14a/components/language-select', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  var languageSelect = Ember['default'].Component.extend({
    tagName: 'select',
    classNames: ['language-select'],
    id: 'btn btn-default btn-xs dropdown-toggle',
    type: 'button',
    datatoggle: 'dropdown',
    i18n: inject.service(),
    current: computed.readOnly('i18n.locale'),

    locales: computed('i18n.locales', function () {
      var i18n = this.get('i18n');
      return this.get('i18n.locales').map(function (loc) {
        return { id: loc, text: i18n.t('language-select.language.' + loc) };
      });
    }),

    // It would be nice to do this with `{{action "setLocale" on="change"}}`
    // in the template, but the template doesn't include the component's own
    // tag yet. See https://github.com/emberjs/rfcs/pull/60
    change: function change() {

      this.get('i18n').set('locale', this.$().val());

      console.log(this.get('store'));
      this.get('store').set('spracheoutput', this.$().val());

      var applicationModel = this.get('arr');
      console.log(applicationModel.objectAt(0));
    }
  });

  exports['default'] = languageSelect;

});