define('m14a/components/schraube-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schrauben = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraube-n',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      return values;
    }).property(''),

    translation: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var x = 0,
          y = 0;

      // console.log('schrauben translation');

      if (x3d.get('ergebnisGeladen') === true) {
        var xKanteLager = -Number(x3d.get('laengeFehlstelle')) / 10 - Number(x3d.get('ueberkopplungslaenge')) / 10 - 0.4;

        x = xKanteLager;
        y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')) / 10;
      }

      var values = x + ' ' + y + ' ' + 0;
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var values = '0 0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    schraubenListe: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var count = 0;
      var schrListe = Ember['default'].Set.create();

      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
          count++;
          if (x3d.get('ergebnisGeladen') === false && x3d.get('schraubenGekreuzt') === false && count > 4) {
            return;
          }
          schrListe.add(item);
        }
      });

      // if(x3d.get('ergebnisGeladen')){
      //   this.setAbstaende(schrListe);
      // }

      return schrListe.toArray();
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    setAbstaende: function setAbstaende(schrliste) {
      var x3ddefault = this.get('model').objectAt(0);

      var liste1 = [];
      var liste2 = [];

      var randAbstand1 = 0,
          randAbstand2 = 0,
          schraubenAbstand1 = 0,
          schraubenAbstand2 = 0;

      schrliste.forEach(function (item) {
        var xVerschiebung = item.get('translations').objectAt(0).get('x');
        var yVerschiebung = item.get('translations').objectAt(0).get('y');
        var zVerschiebung = item.get('translations').objectAt(0).get('z');

        var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length');
        var alpha = item.get('rotations').objectAt(0).get('z');
        var beta = item.get('rotations').objectAt(0).get('y');

        var xKopfPos = Math.cos(beta) * (Math.cos(alpha) * schraubenlaenge) + xVerschiebung;
        var zKopfPos = zVerschiebung + Math.sin(beta) * (yVerschiebung / Math.tan(alpha));

        if (xKopfPos > 0) {
          liste2.push(zKopfPos);
        } else {
          liste1.push(zKopfPos);
        }
      });

      liste1.sort(function (a, b) {
        return a - b;
      });
      liste2.sort(function (a, b) {
        return b - a;
      });

      x3ddefault.set('randAbstand1', Math.abs(randAbstand1));
      x3ddefault.set('randAbstand2', Math.abs(randAbstand2));

      x3ddefault.set('schraubenAbstand1', schraubenAbstand1);
      x3ddefault.set('schraubenAbstand2', schraubenAbstand2);
    },

    schraubenabstaende1: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand1') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand1', 'model.firstObject.schraubenlisteAktualisiert'),

    schraubenabstaende2: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand2') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand2', 'model.firstObject.schraubenlisteAktualisiert'),

    gekreuzteSchrauben: (function () {
      if (this.get('model').objectAt(0).get('schraubenGekreuzt')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenGekreuzt'),

    abstaendeAnzeigen: (function () {
      if (this.get('model').objectAt(0).get('abstaendeAnzeigen') && this.get('model').objectAt(0).get('ergebnisGeladen')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.abstaendeAnzeigen'),

    ergebnisGeladen: (function () {
      return this.get('model').objectAt(0).get('ergebnisGeladen');
    }).property('model.firstObject.ergebnisGeladen'),

    ergebnisGroupRotation: (function () {

      var values = 1 + ' ' + 0 + ' ' + 0 + ' ' + Math.PI;

      return values;
    }).property('model.firstObject.transformHelper'),

    ergebnisVerschiebung: (function () {

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var y = Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y')) / 10;

      var values = 0 + ' ' + y + ' ' + 0;

      return values;
    }).property('model.firstObject.transformHelper')

  });

  exports['default'] = Schrauben;

});