define('m14a/models/treemodel', ['exports', 'ember-data'], function (exports, DS) {

    'use strict';

    exports['default'] = DS['default'].Model.extend({

        title: DS['default'].attr("String"),
        children: DS['default'].hasMany('tag', { async: true }),
        parent: DS['default'].belongsTo('tag', { async: true })

    });

});