define('m14a/models/system', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var system = DS['default'].Model.extend({

		FastenerPosition: DS['default'].attr('string'),
		sFastener: DS['default'].attr('string'),
		AngleGRAD_Fastener: DS['default'].attr('string'),
		Var_sVM: DS['default'].attr('string'),
		e0_VM: DS['default'].attr('string'),

		TopToBottom: DS['default'].attr('boolean'),

		ETA_friction: DS['default'].attr('boolean'),
		ETA_friction_value: DS['default'].attr('string')

	});

	system.reopenClass({
		FIXTURES: [{
			id: 1,
			FastenerPosition: "0",
			sFastener: "0",
			AngleGRAD_Fastener: "45",
			e0_VM: "0",
			Var_sVM: "0",

			TopToBottom: true,

			ETA_friction: false,
			ETA_friction_value: "0"

		}]
	});

	exports['default'] = system;

});