define('m14a/routes/downloadcenter', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    i18n: Ember['default'].inject.service(),

    setupController: function setupController() {
      this.controllerFor('application').miniertX3D();
    }

  });

});