define('m14a/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var name = this.get('name');

      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = bauteile.findBy('id', name);

      var x = Number(item.get('translations').objectAt(0).get('x')) / 10;
      var y = Number(item.get('translations').objectAt(0).get('y')) / 10;
      var z = Number(item.get('translations').objectAt(0).get('z')) / 10;

      var xMaterial1 = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('x'));

      if (x3d.get('ergebnisGeladen') === false) {
        x = x * xMaterial1 / 3 - 0.2;
      }

      values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var drehwinckelZAchse = item.get('rotations').objectAt(0).get('z');
      var drehwinckelYAchse = item.get('rotations').objectAt(0).get('y');
      var values = '0 0 0 0';

      if (x3d.get('ergebnisGeladen')) {

        var xWertRotationsachse = Math.cos(drehwinckelYAchse);
        var zWertRotationsachse = -Math.sin(drehwinckelYAchse);

        values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse;
      } else {
        values = '1 0 0 ' + drehwinckelZAchse;
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Schraube;

});