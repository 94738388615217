define('m14a/components/indexedlinese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedlinese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var bezug = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        x = item.get('translations').objectAt(0).get('x');
        y = item.get('translations').objectAt(0).get('y');
        z = item.get('translations').objectAt(0).get('z');
        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values;

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'lastenPfeil') {
        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + item.get('rotations').objectAt(0).get('winkel');
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var p1 = new Object({ x: 0, y: 0, z: 0 });
      var p2 = new Object({ x: 0, y: 0, z: 0 });

      var drehachsen = new Object({ x: 0, y: 0, z: 0 });
      var drehwinkel = new Object({ x: 0, y: 0, z: 0 });

      var w90Grad = 89 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;

      var item = x3d.get('bauteile').findBy('id', name);

      var zVerschiebung = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z')) / 20;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var hoeheLastenpfeil = bauteile.findBy('id', 'gleichlast1').get('boxsizes').objectAt(0).get('y') + 0.1;

      if (item.get('typ') === 'profilstahl') {
        if (typ === 'kontur' && (name === 'uProfil1' || name === 'uProfil2')) {
          cords = this.getKoordinatenUProfil1u2();
        } else if (typ === 'kontur' && (name === 'uProfil3' || name === 'uProfil4')) {
          cords = this.getKoordinatenUProfil3u4();
        }
      }

      if (item.get('typ') === 'box' || item.get('typ') === 'profilstahl') {

        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (typ === 'kontur' && item.get('typ') !== 'profilstahl') {
          cords = this.getKonturKoordinaten(x, y, z);
        }

        if (typ === 'faserrichtung' && name.substring(0, 8) === 'material') {
          var delta = 0.15;

          x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
          y = 0.15;
          z = item.get('boxsizes').objectAt(0).get('z') / 20;

          cords = -x + 0.15 + delta + ' ' + (y + delta * 2) + ' ' + z + ', ' + (-x + 0.15) + ' ' + (y + delta) + ' ' + z + ', ' + (-x + 1) + ' ' + (y + delta) + ' ' + z + ', ' + (-x + 0.85) + ' ' + y + ' ' + z;
        }

        if (typ === 'breite') {
          switch (name) {
            case 'material1':
              p1.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 10 - x3d.get('laengeFehlstelle') / 10;
              p1.y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 10 - x3d.get('laengeFehlstelle') / 10;
              p2.y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.x = p1.x - abstandVomBauteil;
                p2.x = p1.x;

                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = w90Grad;
              } else if (!bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.y = p1.y - abstandVomBauteil;
                p2.y = p1.y;

                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = w180Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'material23':
              p1.x = 0.4 + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x') / 10;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y'))) {
                p1.y = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10;
              } else {
                p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              }

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.x = p1.x + abstandVomBauteil;

                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = -w90Grad;
              } else if (!bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.y = p1.y + abstandVomBauteil;

                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = 0;
              }
              p2.x = p1.x;
              p2.y = p1.y;

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'uProfil3':
              p1.x = 0.45 + bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x') / 10;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y'))) {
                p1.y = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10;
              } else {
                p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              }

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.x = p1.x + abstandVomBauteil;

                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = -w90Grad;
              } else if (!bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.y = p1.y + abstandVomBauteil;

                drehwinkel.x = 0;
                drehwinkel.y = -w90Grad;
                drehwinkel.z = 0;
              }
              p2.x = p1.x;
              p2.y = p1.y;

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'lager1':
              p1.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              p2.y = 0;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.z = p1.z + abstandVomBauteil;
                p2.z = p1.z;

                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
              } else if (!bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.y = p1.y - abstandVomBauteil;
                p2.y = p1.y;

                drehwinkel.x = w180Grad;
                drehwinkel.y = 0;
                drehwinkel.z = 0;
              }

              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
          }
        }

        if (typ === 'hoehe') {
          switch (name) {
            case 'material12':
              p1.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p1.y = 0;

              p2.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
              p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.z = abstandVomBauteil + zVerschiebung + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 10;
                p2.z = abstandVomBauteil + zVerschiebung + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 10;

                drehwinkel.x = w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              } else if (!bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.x = p1.x + abstandVomBauteil;
                p2.x = p1.x;

                p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
                p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = -w90Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'material23':
              p1.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = -(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20);

              p2.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              p2.y = 0;
              p2.z = -(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20);

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.z = p1.z - abstandVomBauteil;
                p2.z = p1.z;

                drehwinkel.x = -w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              } else if (!bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {

                p1.x = p1.x - 0.15;
                p2.x = p1.x;

                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
            case 'uProfil3':
              p1.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
              p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              p2.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              p2.y = 0;
              p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

              if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
                p1.z = p1.z - abstandVomBauteil;
                p2.z = p1.z;

                drehwinkel.x = -w90Grad;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              } else if (!bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {

                p1.x = p1.x - 0.15;
                p2.x = p1.x;

                drehwinkel.x = 0;
                drehwinkel.y = 0;
                drehwinkel.z = w90Grad;
              }
              cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
              break;
          }
        }

        if (typ === 'laenge' && name === 'material1') {

          p1.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 10 + 0.1;
          p1.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

          p2.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 10) - 0.1 - x3d.get('laengeFehlstelle') / 10 - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 10;
          p2.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

          if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'))) {
            p1.y = p1.y + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) / 10;
            p2.y = p2.y + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) / 10;
          } else {
            p1.y = p1.y + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y')) / 10;
            p2.y = p2.y + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y')) / 10;
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.z = -abstandVomBauteil - zVerschiebung - Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 10);
            p2.z = -abstandVomBauteil - zVerschiebung - Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 10);

            drehwinkel.x = -w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else if (!bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.y = p1.y + 2 * abstandVomBauteil + hoeheMasskette;
            p2.y = p1.y;
            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }
          cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
        }

        if (typ === 'AbstandLager' && name === 'lager1') {

          p1.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + Number(x3d.get('AbstandLager')) / 10;
          p1.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

          p2.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
          p2.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

          var material = '';

          if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'))) {
            material = 'material1';
          } else {
            material = 'material2';
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.z = -hoeheMasskette - 2 * abstandVomBauteil - Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            p2.z = -hoeheMasskette - 2 * abstandVomBauteil - Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            p1.y = p1.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;
            p2.y = p2.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;

            drehwinkel.x = -w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else if (!bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {

            p1.y = 3 * abstandVomBauteil + 2 * hoeheMasskette + p1.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;
            p2.y = 3 * abstandVomBauteil + 2 * hoeheMasskette + p2.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;

            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }
          cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
        }

        if (typ === 'AbstandVM' && name === 'lager1') {

          p1.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + Number(x3d.get('AbstandLager')) / 10 + Number(x3d.get('AbstandVM')) / 10;
          p1.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

          p2.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + Number(x3d.get('AbstandLager')) / 10;
          p2.y = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10;
          p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

          var material = '';

          if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'))) {
            material = 'material1';
          } else {
            material = 'material2';
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.z = -hoeheMasskette - 2 * abstandVomBauteil - Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            p2.z = -hoeheMasskette - 2 * abstandVomBauteil - Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            p1.y = p1.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;
            p2.y = p2.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;

            drehwinkel.x = -w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else if (!bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {

            p1.y = 3 * abstandVomBauteil + 2 * hoeheMasskette + p1.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;
            p2.y = 3 * abstandVomBauteil + 2 * hoeheMasskette + p2.y + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y')) / 10;

            drehwinkel.x = 0;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }
          cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
        }

        if (typ === 'ueberkopplungslaenge' && name === 'material2') {
          p1.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
          p1.y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);

          p2.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 - x3d.get('ueberkopplungslaenge') / 10;
          p2.y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);

          if (x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
            p1.z = bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z') / 20;
          } else if (x3d.get('zweiseitigeVerstaerkung') === true) {
            p1.z = bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20;
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.z = p1.z + abstandVomBauteil;

            drehwinkel.x = w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else if (!bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.y = p1.y - abstandVomBauteil;

            drehwinkel.x = w180Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }
          p2.y = p1.y;
          p2.z = p1.z;

          cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
        }

        if (typ === 'laengeFehlstelle' && (name === 'uProfil1' || name === 'material2')) {

          // console.log('name: '+name);

          p1.x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 - x3d.get('ueberkopplungslaenge') / 10;
          p1.y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);

          p2.x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) + bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 10;
          p2.y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);

          if (x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
            p1.z = bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z') / 20;
          } else if (x3d.get('zweiseitigeVerstaerkung') === true) {
            p1.z = bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20;
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.z = p1.z + abstandVomBauteil;

            drehwinkel.x = w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else if (!bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.y = p1.y - abstandVomBauteil;

            drehwinkel.x = w180Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }

          p2.y = p1.y;
          p2.z = p1.z;

          cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
        }
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {

        var p0, p3, p4, p5, p6, p7, p8, p9;

        var b05 = 0.05;
        var b1 = 0.1;
        var b5 = 0.5;
        var b45 = b5 - b05;
        var b55 = b5 + b05;
        var b6 = 0.6;

        var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
        var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
        var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

        var schraubenlaenge = (item.get('screwProperties').objectAt(0).get('length') + item.get('screwProperties').objectAt(0).get('headheight')) / 10;
        var alpha = item.get('rotations').objectAt(0).get('z');
        var beta = item.get('rotations').objectAt(0).get('y');

        if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
          alpha = 1.5707 - (alpha - 1.5707);
          xVerschiebung = xVerschiebung * -1;
        }

        var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

        var posFaktor = 1;
        if (alpha > 1.5707) {
          posFaktor = -1;
        }

        var xKopfPos = Math.cos(beta) * (Math.cos(alpha) * schraubenlaenge) + xVerschiebung;
        var yKopfPos = -(Math.sin(alpha) * schraubenlaenge) - yVerschiebung;
        var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

        var xBezugspunkt = 0;
        var yBezugspunkt = 0;
        var zBezugspunkt = 0;

        if (x3d.get('istHt')) {
          zBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('z') / 20 + 0.25;
        } else {
          zBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('y') / 20 + 0.25;
        }

        if (typ === 'xPos') {
          p0 = xKopfPos + ' ' + yBezugspunkt + ' ' + -zBezugspunkt;
          p1 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -zBezugspunkt;
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind')) {
            p2 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b6);
            p3 = xKopfPos + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b6);
            p4 = xKopfPos + posFaktor * b1 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b5);
            p5 = xBezugspunkt - posFaktor * b1 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b5);
            p6 = xBezugspunkt - b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b45);
            p7 = xBezugspunkt + b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b55);
            p8 = xKopfPos - b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b45);
            p9 = xKopfPos + b05 + ' ' + yBezugspunkt + ' ' + -(zBezugspunkt + b55);
          } else if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            p2 = xBezugspunkt + ' ' + (yBezugspunkt + b6) + ' ' + -zBezugspunkt;
            p3 = xKopfPos + ' ' + (yBezugspunkt + b6) + ' ' + -zBezugspunkt;
            p4 = xKopfPos + posFaktor * b1 + ' ' + (yBezugspunkt + b5) + ' ' + -zBezugspunkt;
            p5 = xBezugspunkt - posFaktor * b1 + ' ' + (yBezugspunkt + b5) + ' ' + -zBezugspunkt;
            p6 = xBezugspunkt - b05 + ' ' + (yBezugspunkt + b45) + ' ' + -zBezugspunkt;
            p7 = xBezugspunkt + b05 + ' ' + (yBezugspunkt + b55) + ' ' + -zBezugspunkt;
            p8 = xKopfPos - b05 + ' ' + (yBezugspunkt + b45) + ' ' + -zBezugspunkt;
            p9 = xKopfPos + b05 + ' ' + (yBezugspunkt + b55) + ' ' + -zBezugspunkt;
          }
        }

        if (typ === 'zPos') {

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && !x3d.objectAt(0).get('istHt')) {
            xBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('x') / 10 + 0.75;
          } else {
            xBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('x') / 10 + 0.3;
          }

          if (zKopfPos > 0) {
            posFaktor = 1;
            zBezugspunkt = -x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('z') / 20;
          } else {
            posFaktor = -1;
            zBezugspunkt = x3d.get('bauteile').findBy('id', 'sparren2').get('boxsizes').objectAt(0).get('z') / 20;
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') || bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind')) {
            if (zKopfPos > 0) {
              xBezugspunkt = xBezugspunkt + 0.4;
            }
            p0 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -zKopfPos;
            p1 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + zBezugspunkt;
            p2 = xBezugspunkt + b6 + ' ' + yBezugspunkt + ' ' + zBezugspunkt;
            p3 = xBezugspunkt + b6 + ' ' + yBezugspunkt + ' ' + -zKopfPos;
            p4 = xBezugspunkt + b5 + ' ' + yBezugspunkt + ' ' + (-zKopfPos + b1 * posFaktor);
            p5 = xBezugspunkt + b5 + ' ' + yBezugspunkt + ' ' + (zBezugspunkt - b1 * posFaktor);
            p6 = xBezugspunkt + b45 + ' ' + yBezugspunkt + ' ' + (zBezugspunkt - b05);
            p7 = xBezugspunkt + b55 + ' ' + yBezugspunkt + ' ' + (zBezugspunkt + b05);
            p8 = xBezugspunkt + b45 + ' ' + yBezugspunkt + ' ' + (-zKopfPos - b05);
            p9 = xBezugspunkt + b55 + ' ' + yBezugspunkt + ' ' + (-zKopfPos + b05);
          } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            if (zKopfPos > 0) {
              yBezugspunkt = yBezugspunkt + 0.4;
            }
            p0 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + -zKopfPos;
            p1 = xBezugspunkt + ' ' + yBezugspunkt + ' ' + zBezugspunkt;
            p2 = xBezugspunkt + ' ' + (yBezugspunkt + b6) + ' ' + zBezugspunkt;
            p3 = xBezugspunkt + ' ' + (yBezugspunkt + b6) + ' ' + -zKopfPos;
            p4 = xBezugspunkt + ' ' + (yBezugspunkt + b5) + ' ' + (-zKopfPos + b1 * posFaktor);
            p5 = xBezugspunkt + ' ' + (yBezugspunkt + b5) + ' ' + (zBezugspunkt - b1 * posFaktor);
            p6 = xBezugspunkt + ' ' + (yBezugspunkt + b45) + ' ' + (zBezugspunkt - b05);
            p7 = xBezugspunkt + ' ' + (yBezugspunkt + b55) + ' ' + (zBezugspunkt + b05);
            p8 = xBezugspunkt + ' ' + (yBezugspunkt + b45) + ' ' + (-zKopfPos - b05);
            p9 = xBezugspunkt + ' ' + (yBezugspunkt + b55) + ' ' + (-zKopfPos + b05);
          }
        }
        cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;
      }

      if (this.get('typ') === 'vWinkel' && name === 'hauptTraeger1') {
        var r1 = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x')) / 20 + 3.5;

        var w1 = 0;
        var w2 = x3d.get('vWinkel');

        cords = this.getArc2dCords(w1 * Math.PI / 180, w2 * Math.PI / 180, r1);
      }

      if (name === 'lastenPfeil') {
        cords = '0 0 0 0 -1 0';
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.lastViewpoint'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (item.get('typ') === 'profilstahl') {
        if (typ === 'kontur' && (name === 'uProfil1' || name === 'uProfil2')) {
          values = "0 1 5 4 8 9 13 12 0 -1 2 3 15 14 10 11 7 6 2 -1 0 3 -1 1 2 -1 5 6 -1 4 7 -1 8 11 -1 9 10 -1 13 14 -1 12 15 -1";
        } else if (typ === 'kontur' && (name === 'uProfil3' || name === 'uProfil4')) {
          values = "0 1 13 12 8 9 5 4 0 -1 2 3 7 6 10 11 15 14 2 -1 0 3 -1 1 2 -1 5 6 -1 4 7 -1 8 11 -1 9 10 -1 13 14 -1 12 15 -1";
        }
      }

      if (item.get('typ') === 'box') {

        if (typ === 'kontur') {
          values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7";
        }

        if (typ === 'faserrichtung') {
          values = "0 1 -1 1 2 -1 2 3 -1";
        }
      } else {
        if (name === 'lastenPfeil') {
          values = "0 1 -1";
        }

        if (name.substring(0, 8) === 'schraube' && x3d.objectAt(0).get('ergebnisGeladen')) {
          if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }
      }

      if (this.get('typ') === 'vWinkel' && name === 'hauptTraeger1') {
        values = "0 1 -1 2 3 -1";
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    istLastenpfeil: (function () {
      var istLastenpfeil = false;

      if (this.get('name') === 'lastenPfeil') {
        istLastenpfeil = true;
      }
      return istLastenpfeil;
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'material1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'material12' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('breiteMaterial2Aktiv') === true && name === 'material23' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hoeheMaterial2Aktiv') === true && name === 'material23' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('systemlaengeAktiv') === true && name === 'material1' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('ueberkopplungslaengeAktiv') === true && name === 'material2' && typ === 'ueberkopplungslaenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('laengeFehlstelleAktiv') === true && name === 'material2' && typ === 'laengeFehlstelle') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('auflagerlaengeAktiv') === true && name === 'lager1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.breiteMaterial2Aktiv', 'model.firstObject.hoeheMaterial2Aktiv', 'model.firstObject.systemlaengeAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.laengeFehlstelleAktiv', 'model.firstObject.ueberkopplungslaengeAktiv', 'model.firstObject.auflagerlaengeAktiv'),

    getKonturKoordinaten: function getKonturKoordinaten(x, y, z) {

      var p0 = -x / 2 + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x / 2 + ' ' + 0 + ' ' + z / 2;
      var p2 = x / 2 + ' 0 ' + z / 2;
      var p3 = x / 2 + ' 0 ' + -z / 2;
      var p4 = -x / 2 + ' ' + y + ' ' + -z / 2;
      var p5 = -x / 2 + ' ' + y + ' ' + z / 2;
      var p6 = x / 2 + ' ' + y + ' ' + z / 2;
      var p7 = x / 2 + ' ' + y + ' ' + -z / 2;
      var p8 = 0;
      var p9 = 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    getMassketteKoordinaten: function getMassketteKoordinaten(punkt1, punkt2, drehachsen, drehwinkel) {

      var name = this.get('name');
      var typ = this.get('typ');

      // var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      var skalierungsfaktor = 1;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;

      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;

      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;

      dp = this.drehungXAchse({ x: 0, y: b6, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: -b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: -b05, y: b45, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: b05, y: b55, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    drehungXAchse: function drehungXAchse(v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungYAchse: function drehungYAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungZAchse: function drehungZAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },

    getKoordinatenUProfil1u2: function getKoordinatenUProfil1u2() {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var x = Number(item.get('boxsizes').objectAt(0).get('x')) / 10;
      var y = Number(item.get('boxsizes').objectAt(0).get('y')) / 10;
      var z = Number(item.get('boxsizes').objectAt(0).get('z')) / 10;

      var wandDicke = Number(bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('materialdicke')) / 10;

      p0 = -(x / 2) + ' ' + 0 + ' ' + -(z / 2);
      p1 = -(x / 2) + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -(z / 2);
      p4 = -(x / 2) + ' ' + wandDicke + ' ' + (-z / 2 + wandDicke);
      p5 = -(x / 2) + ' ' + wandDicke + ' ' + z / 2;
      p6 = x / 2 + ' ' + wandDicke + ' ' + z / 2;
      p7 = x / 2 + ' ' + wandDicke + ' ' + (-z / 2 + wandDicke);
      p8 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (-z / 2 + wandDicke);
      p9 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + z / 2;
      p10 = x / 2 + ' ' + (y - wandDicke) + ' ' + z / 2;
      p11 = x / 2 + ' ' + (y - wandDicke) + ' ' + (-z / 2 + wandDicke);
      p12 = -(x / 2) + ' ' + y + ' ' + -(z / 2);
      p13 = -(x / 2) + ' ' + y + ' ' + z / 2;
      p14 = x / 2 + ' ' + y + ' ' + z / 2;
      p15 = x / 2 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;
      return koordinaten;
    },

    getKoordinatenUProfil3u4: function getKoordinatenUProfil3u4() {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var x = Number(item.get('boxsizes').objectAt(0).get('x')) / 10;
      var y = Number(item.get('boxsizes').objectAt(0).get('y')) / 10;
      var z = Number(item.get('boxsizes').objectAt(0).get('z')) / 10;

      var wandDicke = Number(bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('materialdicke')) / 10;

      p0 = -(x / 2) + ' ' + 0 + ' ' + -(z / 2);
      p1 = -(x / 2) + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -(z / 2);
      p4 = -(x / 2) + ' ' + wandDicke + ' ' + -z / 2;
      p5 = -(x / 2) + ' ' + wandDicke + ' ' + (z / 2 - wandDicke);
      p6 = x / 2 + ' ' + wandDicke + ' ' + (z / 2 - wandDicke);
      p7 = x / 2 + ' ' + wandDicke + ' ' + -z / 2;
      p8 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + -z / 2;
      p9 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (z / 2 - wandDicke);
      p10 = x / 2 + ' ' + (y - wandDicke) + ' ' + (z / 2 - wandDicke);
      p11 = x / 2 + ' ' + (y - wandDicke) + ' ' + -z / 2;
      p12 = -(x / 2) + ' ' + y + ' ' + -(z / 2);
      p13 = -(x / 2) + ' ' + y + ' ' + z / 2;
      p14 = x / 2 + ' ' + y + ' ' + z / 2;
      p15 = x / 2 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;
      return koordinaten;
    }

  });

  exports['default'] = Transform;

});