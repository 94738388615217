define('m14a/models/bauteile', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var bauteile = DS['default'].Model.extend({

		MatKeyTimberElement1: DS['default'].attr('string'),
		FKLKeyTimberElement1: DS['default'].attr('string'),
		TimberElement1_b: DS['default'].attr('string'),
		TimberElement1_h: DS['default'].attr('string'),
		MatKeyTimberElement2: DS['default'].attr('string'),
		FKLKeyTimberElement2: DS['default'].attr('string'),
		TimberElement2_b: DS['default'].attr('string'),
		TimberElement2_h: DS['default'].attr('string'),
		ALFA_Elements_RAD: DS['default'].attr('string'),
		CorrosionResistanceNecessary: DS['default'].attr('boolean')

	});

	bauteile.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyTimberElement1: "0",
			FKLKeyTimberElement1: "5",
			TimberElement1_b: "20",
			TimberElement1_h: "20",
			MatKeyTimberElement2: "0",
			FKLKeyTimberElement2: "5",
			TimberElement2_b: "20",
			TimberElement2_h: "20",
			ALFA_Elements_RAD: "0",
			CorrosionResistanceNecessary: false
		}]
	});

	exports['default'] = bauteile;

	// bauteile.reopenClass({
	// 	FIXTURES: [
	// 		{
	// 			id: 1,
	// 			MatKeyTimberElementHT: "0",
	// 			FKLKeyTimberElementHT: "5",
	// 			TimberElementHT_b: "20",
	// 			TimberElementHT_h: "20",			
	// 			MatKeyTimberElementNT: "0",
	// 			FKLKeyTimberElementNT: "5",
	// 			TimberElementNT_b: "20",
	// 			TimberElementNT_h: "20",			
	// 		}
	// 	]
	// });

});