define('m14a/routes/anschlussgeometrie', ['exports', 'ember'], function (exports, Ember) {

	'use strict';

	exports['default'] = Ember['default'].Route.extend({
		i18n: Ember['default'].inject.service(),

		model: function model() {
			return this.store.find('anschlussgeometrie');
		},

		setupController: function setupController() {
			this.controllerFor('application').miniertX3D();
		}
	});

});