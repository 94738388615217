define('m14a/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({
    debug: true,
    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var self = this;
          var application = self.controllerFor('application');

          var applicationdata = application.get('model').application.objectAt(0);
          var applicationdataJSON = JSON.stringify(applicationdata);

          var projektdatendata = application.get('model').projektdaten.objectAt(0);
          var projektdatendataJSON = JSON.stringify(projektdatendata);

          var supercontroller = self.controllerFor('supercontroller');
          var JSONdata = supercontroller.uebergabedatenErzeugen();

          var server = application.get('server');
          var pfad = application.get('pfad');

          var cid = applicationdata.get('Calculation_ID');

          if (self.debug) {
            console.log('cid: ' + cid);
            console.log('server: ' + server);
            console.log('pfad: ' + pfad);
            console.log('nameOfSender: ' + nameOfSender);
            console.log('messageToSend: ');
            console.log(messageToSend);
            console.log('kennung: ');
            console.log(applicationdataJSON);
            console.log('paras:');
            console.log(JSONdata);
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: 'M14a HTML CLIENT - Kontaktformular / Anfrage',
              sender: email,
              receiver: "support@swg-engineering.de",
              suffix: "wu14a"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            self.set('nachrichtVerfassen', false);
            console.log(data);
            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }

    }
  });

});