define('m14a/models/traeger', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var traeger = DS['default'].Model.extend({

		MatKeyTimberElement: DS['default'].attr('string'),
		FKLKeyTimberElement: DS['default'].attr('string'),
		TimberElement_b: DS['default'].attr('string'),
		TimberElement_h: DS['default'].attr('string'),
		TimberElement_Predrilled: DS['default'].attr('boolean', {
			defaultValue: function defaultValue() {
				return false;
			}
		}),
		ALFA_RAD_LoadGrain: DS['default'].attr('string')
	});

	traeger.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyTimberElement: "0",
			FKLKeyTimberElement: "5",
			TimberElement_b: "20",
			TimberElement_h: "20",
			TimberElementHT_Predrilled: false,
			ALFA_RAD_LoadGrain: "0.0"
		}]
	});

	exports['default'] = traeger;

});